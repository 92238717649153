












import {
  defineComponent,
} from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'PostsParentPage',
  layout: 'newsletter',
  middleware: [
    'rights',
  ],
  head: {},
})
