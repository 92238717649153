import { render, staticRenderFns } from "./posts.vue?vue&type=template&id=8f0c9334&scoped=true&"
import script from "./posts.vue?vue&type=script&lang=ts&"
export * from "./posts.vue?vue&type=script&lang=ts&"
import style0 from "./posts.vue?vue&type=style&index=0&id=8f0c9334&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f0c9334",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PublicationSummary: require('/tmp/build_84effd69/apps/web/components/publication/PublicationSummary.vue').default})
